function obtenerAnioCompleto(ultimosDigitos){
  ultimosDigitos = parseInt(ultimosDigitos, 10)
  const anioActual = new Date().getFullYear()
  const primDigitosAnioActual = Math.floor(anioActual/100)

  var siglo;
  // �ltimos d�gitos de a�o actual 
  if ((anioActual % 100) > ultimosDigitos) {
      siglo = primDigitosAnioActual * 100;
  } else {
      siglo = (primDigitosAnioActual - 1) * 100;
  }

  var anioCompleto = siglo + ultimosDigitos;
  return ""+anioCompleto
}

export function fechaNacimientoDeRFC(rfc){    
  const fecha = rfc.length == 12 ? rfc.substring(3, 9): rfc.substring(4, 10);
  
  var anio = fecha.substring(0, 2);
  const mes = fecha.substring(2, 4);
  const dia = fecha.substring(4, 6);

  anio = obtenerAnioCompleto(anio);

  return `${dia}/${mes}/${anio}`;
}

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function formatoDinero(value) {
  return "$" + value.toLocaleString("es-MX", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function textoAMoneda(monto) {
  monto = monto.replace(/[^0-9.]/g, "");                     
  let parts = monto.split(".");

  if (parts.length < 2){
    return `$ ${new Intl.NumberFormat('es-MX').format(monto)}`;
  }else{
    // Permito el primer punto decimal
    if (parts.length > 2) {
      monto = parts[0] + "." + parts[1];
    }
    
    // Permito los primeros dos d�gitos despu�s del punto
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
    }

    // Agrega d�gitos despu�s del punto
    if (parts[1]){
      monto = `$ ${new Intl.NumberFormat('es-MX').format(parts[0])}.` + parts[1];
    }else{ // Coloca el punto decimal
      monto = `$ ${new Intl.NumberFormat('es-MX').format(monto)}.`;
    }

    return monto;
  }
}

export function agergarDecimales(monto) {
  let numero = monto.replace(/[^0-9.]/g, "");
  
  if(/^\d+$/.test(numero)){
    return monto + ".00";
  }else if (/^\d+\.$/.test(numero)){
    return monto + "00";
  }else if (/^\d+\.\d{1}$/.test(numero)){
    return monto + "0";
  }
  return monto;
}

export function formatoMoneda(numero){
  return `$ ${new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(numero)}`;
}

export function formatoIsoFecha(fecha) {
  return fecha.split("T")[0];
}

